<template>
    
    <div >


        <div class="timeline">

            <template v-for="(item, index) in this.form.timeLine" :key="item">
                
                <div :class="['timeline-item', index % 2 === 0 ? 'left' : 'right']">
                    <span class="icon">{{ index+1 }}</span>
                    <div class="date fw-semibold fs-16" style="top:40px" >{{ mutateStatus(item.status).name }}</div>
                    <div class="date" style="top: 70px;">{{ item.date }}</div>
                    
                    <div class="content" >
                        <div class="d-flex" >
                            <div class="flex-shrink-0" >
                                <div class="avatar-xs"  style="margin: 0px auto;" >
                                    <div class="avatar-title rounded bg-soft-info text-info"  >
                                        {{ this.getInitials(item.nameWorker ) }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1 ms-3" >
                                <h5 class="fs-15">{{ item.nameWorker }} <small class="text-muted fs-13 fw-normal">- {{ item.time }}</small></h5>
                                <p class="text-muted mb-2">
                                    {{ item.comment }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </template>

        </div>

    </div>


</template>

<script>
import { mutateStatusCandidate } from '@/usabilityScripts/globalMutate.js'

export default {
    props: ['objCard'],
    data() {
        return {
            form: ''
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        getInitials(string) {
            // Отримання ініціалів з імені користувача

            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        mutateStatus(e){
            // мутейт статусу кандидата
            return mutateStatusCandidate(e)
        },
    }
    
}
</script>