<template>
    <dialogBox >
        <template v-slot:title>{{ $t('Змінити статус') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-left">
                <h3 class="mb-3"><b>{{ $t('Оберіть новий статус') }}</b></h3>
                <span class="text-muted fs-12 mb-3">Оберіть статус кандидата</span>
                <b-form-select class="mt-3" v-model="form.status" :options="options"></b-form-select>

                <div class="mt-3">
                    <label >Коментар</label>
                    <textarea class="form-control"></textarea>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="change">{{ $t('Edit') }}</button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'

export default{
    props: ['objCard'],
    components: {
        dialogBox,
    },
    data(){
        return{
            form: {
                status: ''
            },
            options: [
                { value: 'new_request', text: 'Нова заявка' }, // Нова заявка
                { value: 'will_interview', text: 'Назначена співбесіда' },  // Назначена співбесіда
                { value: 'denied', text: 'Відмовлено' },  // Відмовлено
                { value: 'under_question', text: 'Під питанням' },  // Під питанням
                { value: 'intern', text: 'Стажується' },  // Стажується
                { value: 'not_pass_internship', text: 'Не пройшов стажування' }, // Не пройшов стажування
                { value: 'works', text: 'Працює' },  // Працює
                { value: 'released', text: 'Звільнено' }  // Звільнено
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata() {
            this.form = this.objCard
        }
    }
}
</script>